// Variables
@import "../../../sass/variables";

.dialog {
  display: none;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 990;

  .dialog-bg {
    background: rgba(0, 0, 0, 0.8);
    height: 100vh;
    position: absolute;
    width: 100%;
  }

  .dialog-content {
    width: 50%;
    background: #fff;
    top: 50%;
    left: 50%;
    position: relative;
    transform: translate(-50%, -50%);
    border-radius: 15px;

    .dialog-title {
      font-size: 23px;
      font-weight: bold;
    }

    .dialog-close {
      width: 20px;
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }

    .page-title {
      margin: 0 20px;
    }

    .content {
      padding: 15px;
      text-align: center;
    }

    .bottom-button-aria {
      margin-top: 50px;
      display: flex;
      justify-content: center;
    }
  }
}

// タブレット対応
@media (min-width: 768px) and (max-width: 1079px) {
  .dialog {
    .dialog-content {
      min-width: 65%;
      max-height: 85% !important;
      overflow: scroll !important;
    }
  }
}

// スマートフォン対応
@media (max-width: 767px) {
  .dialog {
    .dialog-content {
      width: 90% !important;
      max-height: 80% !important;
      overflow: scroll !important;
      padding: 20px 0 20px 10px !important;

      .page-title {
        width: 76%;
      }

      .bottom-button-aria {
        flex-direction: column;

        // スマホ並び順変更用
        .order1 {
          order: 1;
        }

        .order2 {
          order: 2;
        }
      }
    }
  }
}
